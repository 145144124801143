
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

// Global styles
html,
body {
  @include hz-textStyle(body-regular);
  color: hz-color(text-primary);
}

.hz-Page-container {
  background-color: hz-color(background-light);
  padding: hz-spacing(m) 0;
}

.hz-Page-container--embedded {
  padding-top: hz-spacing(m);
}

.hz-Page-content {
  justify-content: space-around;
}

.hz-Page-element--main {
  display: contents;

  @include hz-breakpoint-up(m) {
    display: block;
    flex: 0 0 65%;
    max-width: 65%;
  }
}

.root-element {
  display: contents;
}

a {
  color: hz-color('signal-link-default');
  text-decoration: none;

  &:hover {
    color: hz-color('signal-link-hover');
  }

  &:active {
    color: hz-color('signal-link-active');
  }
}

.hz-Button:disabled {
  filter: opacity(0.5) !important;
  cursor: not-allowed;
}
