
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.remember {
  display: flex;
  justify-content: space-between;
}

/* stylelint-disable */
$google-blue: #1a73e8;
/* stylelint-enable */

.googleButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: hz-spacing('xxxxs');
  border: 0;
  border-radius: hz-spacing('xxxs');
  background-color: $google-blue;
  color: hz-color('background-default');
  cursor: pointer;
}

.googleButtonIcon {
  padding: hz-spacing('xxs') - 2;
  border-radius: hz-spacing('xxxs') 0 0 hz-spacing('xxxs');
  background-color: hz-color('background-default');
}

.googleButtonLogo {
  background-color: hz-color('background-default');
}

.googleButtonText {
  padding: 0 hz-spacing('xs');
}

.socialLogin {
  display: flex;
  justify-content: center;
}

:global(#google-signin-button) {
  margin: 0 auto;
}
