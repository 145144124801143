
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  @include hz-textStyle(body-regular);
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;

  @include hz-breakpoint-m-up {
    margin: 0 hz-spacing(m);
  }

  :global(.hz-ButtonsBlock) {
    padding: hz-spacing(m) hz-spacing(l);
  }
}

.card {
  box-shadow: none;
  @include hz-breakpoint-m-up {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15),0 -1px 2px rgba(0, 0, 0, 0.05);
  }
}

h1 {
  @include hz-textStyle--title2;
  margin-bottom: hz-spacing(m);
}

.remember {
  display: flex;
  justify-content: space-between;
}

.socialLogin {
  display: flex;
  justify-content: center;
}

:global(#google-signin-button) {
  margin: 0 auto;
}

:global(::-webkit-scrollbar) {
  display: none;
}
